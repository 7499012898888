import React, { FC, useState, useEffect } from 'react';
import { Button, Card, DatePicker, Skeleton, Pagination, Select } from 'antd';
import PageTitle from '../../layout/PageTitle/PageTitle';
import SidebarPage from '../../layout/SidebarPage/SidebarPage';
import { useFetchUser } from '../../hooks/useFetchUser';
import LedgerTable from './LedgerTable';
import { useGet, usePost } from '../../hooks/useApi';
import { Student } from '../../models/student.model';
import MakeAPayment from './modals/MakeAPayment';
import { LedgerLine } from '../../models/ledger-line.model';
import dayjs, { Dayjs } from 'dayjs';

const Ledger: FC = () => {
  const user = useFetchUser();
  const initialStartDate = dayjs().subtract(30, 'days');
  const initialEndDate = dayjs();

  const [studentsChecked, setStudentsChecked] = useState<number[]>([]);
  const [startDate, setStartDate] = useState<Dayjs>(initialStartDate);
  const [endDate, setEndDate] = useState<Dayjs>(initialEndDate);
  const [modal, setModal] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterBy, setFilterBy] = useState<string>('all');
  const [pageSize] = useState<number>(1000);

  const [printTable, setPrintTable] = useState<number>(0);

  const students = useGet<Student[]>('/v1/auth/dashboard/students');

  const ledgerLines = usePost<{ count: number; rows: LedgerLine[] }, any>(
    `/v1/auth/dashboard/ledger-lines/search/${(currentPage - 1) * pageSize}/${pageSize}`
  );

  const ledgerLinesSum = usePost<number, any>(
    '/v1/auth/dashboard/ledger-lines/search/sum/amount'
  );

  const search = () => {
    if (!startDate || !endDate) return;

    const variables = {
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      studentIds: filterBy === 'all' ? [] : studentsChecked,
    };

    ledgerLines.mutate({
      ...variables,
      attributes: ['date', 'transaction', 'memo', 'amount'],
    });
    ledgerLinesSum.mutate(variables);
  };

  const isChecked = (id: number) => studentsChecked.includes(id);

  const toggleCheck = (id: number) => {
    setStudentsChecked((prevChecked) =>
      prevChecked.includes(id)
        ? prevChecked.filter((studentId) => studentId !== id)
        : [...prevChecked, id]
    );
  };

  const openModal = (ev: React.MouseEvent) => {
    ev.preventDefault();
    setModal(true);
  };

  const onPaymentSuccess = () => search();

  useEffect(() => {
    setStudentsChecked(students.data?.map((student) => student.id) || []);
    console.log(students.data?.map((student) => student.id));
  }, [students.data]);

  useEffect(() => {
    search();
  }, [studentsChecked, startDate, endDate, currentPage, pageSize, filterBy]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const Header = () => (
    <>
      <div className={'pb-3'}>
        <Button onClick={openModal} id={'make-a-payment'} type={'primary'}>
          Make A Payment
        </Button>
      </div>
      <Card>
        <table className={'w-100'}>
          <tbody>
            <tr>
              <td style={{ width: '80px' }}>Start Date</td>
              <td style={{ width: '140px' }}>
                <DatePicker value={startDate} onChange={setStartDate} />
              </td>
              <td className={'text-center'} style={{ width: '80px' }}>
                End Date
              </td>
              <td style={{ width: '140px' }}>
                <DatePicker value={endDate} onChange={setEndDate} />
              </td>
              <td style={{ width: '140px', paddingLeft: '8px' }}>
                <Select
                  defaultValue={filterBy}
                  onChange={setFilterBy}
                  options={[
                    { value: 'all', label: 'Filter: All from user' },
                    {
                      value: 'student',
                      label: 'Filter: By Student',
                    },
                  ]}
                />
              </td>
              {filterBy === 'student' && (
                <>
                  <td className={'text-center'} style={{ width: '80px' }}>
                    Students
                  </td>
                  <td>
                    <table>
                      <tbody>
                        <tr>
                          {students.data?.map((student) => (
                            <td className={'pr-2'} key={student.id}>
                              <input
                                type={'checkbox'}
                                checked={isChecked(student.id)}
                                onChange={() => toggleCheck(student.id)}
                              />{' '}
                              {student.firstName} {student.lastName}
                            </td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </>
              )}
              <td className={'text-right'}>
                <Button type={'primary'} onClick={search}>
                  Search
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
    </>
  );

  const HeaderMobile = () => (
    <>
      <div className={'text-right pb-3'}>
        <Button onClick={openModal} type={'primary'} block={true}>
          Make A Payment
        </Button>
      </div>
      <Card>
        <div className={'mb-3'}>
          <p>Start Date</p>
          <DatePicker
            style={{ width: '100%' }}
            value={startDate}
            onChange={setStartDate}
          />
        </div>

        <div className={'mb-3'}>
          <p>End Date</p>
          <DatePicker
            style={{ width: '100%' }}
            value={endDate}
            onChange={setEndDate}
          />
        </div>

        <div className={'mb-3'}>
          <p>Filter By</p>
          <Select
            className={'w-100'}
            defaultValue={filterBy}
            onChange={setFilterBy}
            options={[
              { value: 'all', label: 'All from user' },
              {
                value: 'student',
                label: 'Filter by Students',
              },
            ]}
          />
        </div>

        {filterBy === 'student' && (
          <div className={'mb-3'}>
            <p>Students</p>
            <table>
              <tbody>
                {students.data?.map((student) => (
                  <tr key={student.id}>
                    <td className={'pr-2'}>
                      <input
                        type={'checkbox'}
                        checked={isChecked(student.id)}
                        onChange={() => toggleCheck(student.id)}
                      />{' '}
                      {student.firstName} {student.lastName}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className={'mb-3'}>
          <Button type={'primary'} block={true} onClick={search}>
            Search
          </Button>
        </div>
      </Card>
    </>
  );

  return (
    <SidebarPage>
      <PageTitle>
        {user.isLoading
          ? 'Loading...'
          : (user.data?.PrimaryGuardian?.lastName || '') + ' Family Account'}
      </PageTitle>

      {window.innerWidth >= 767 ? <Header /> : <HeaderMobile />}
      <div className={'text-center pt-1 pb-0'}>
        <small style={{ color: '#999' }}>Change Dates To Get A New Range</small>
      </div>

      {!!startDate && !!endDate && (
        <>
          {ledgerLines.isLoading || ledgerLinesSum.isLoading ? (
            <Skeleton active={true} />
          ) : (
            <>
              <div
                className={
                  window.innerWidth < 767
                    ? 'text-right pb-4 pt-3'
                    : 'text-right pb-4'
                }
              >
                <Button
                  block={window.innerWidth < 767}
                  onClick={() => setPrintTable(1)}
                >
                  Print
                </Button>
              </div>
              <Card bodyStyle={{ padding: '0' }}>
                <LedgerTable
                  primaryGuardian={user.data?.PrimaryGuardian}
                  students={(students.data || []).filter((student) =>
                    isChecked(student.id)
                  )}
                  printTable={printTable}
                  onPrintTable={() => setPrintTable(0)}
                  total={ledgerLinesSum.data || 0}
                  rows={ledgerLines.data?.rows || []}
                />
                <div className={'p-2'}>
                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={ledgerLines.data?.count || 0}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </div>
              </Card>
            </>
          )}
        </>
      )}

      <MakeAPayment
        onPaymentSuccess={onPaymentSuccess}
        visible={modal}
        onVisibleChange={setModal}
      />
    </SidebarPage>
  );
};

export default Ledger;
